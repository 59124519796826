/* eslint-disable comma-dangle */
/* eslint-disable quotes */
// The list is in inverted chronological order.
// First items are the latest.

module.exports = [
  // {
  //   id: "6",
  //   image: "https://storage.wsus.me/uploads/sertifikat/expert-react.PNG",
  //   href: "https://www.dicoding.com/certificates/07Z6G7MYWXQR",
  //   title: "Dicoding - Menjadi React Web Developer Expert",
  //   date: "February 03th, 2023",
  //   desc: `Valid until: February 03th, 2026`,
  // },
  // {
  //   id: "5",
  //   image: "https://storage.wsus.me/uploads/sertifikat/expert-fe.JPG",
  //   href: "https://www.dicoding.com/certificates/JMZVN14YJPN9",
  //   title: "Dicoding - Menjadi Frontend Web Developer Expert",
  //   date: "November 21th, 2022",
  //   desc: `Valid until: November 21th, 2025`,
  // },
  // {
  //   id: "0",
  //   image:
  //     "https://storage.wsus.me/uploads/sertifikat/fundamental-react.JPG",
  //   href: "https://www.dicoding.com/certificates/6RPNDLJDRZ2M",
  //   title: "Dicoding - Belajar Fundamental Aplikasi Web dengan React",
  //   date: "Sept 12th, 2022",
  //   desc: `Valid until: Sept 12th, 2025`,
  // },
  {
    id: "1",
    image:
      "https://storage.wsus.me/uploads/sertifikat/fundamental-fe.JPG",
    href: "https://www.dicoding.com/certificates/1RXY6VYEMZVM",
    title: "Dicoding - Belajar Fundamental Front-End Web Development",
    date: "Agustus 8th, 2023",
    desc: `Valid until: Agustus 8th, 2026`,
  },
  // {
  //   id: "4",
  //   image: "https://storage.wsus.me/uploads/sertifikat/fundamental-be.PNG",
  //   href: "https://www.dicoding.com/certificates/1OP85JR4VPQK",
  //   title: "Dicoding - Belajar Fundamental Aplikasi Back-End",
  //   date: "January 19th, 2023",
  //   desc: `Valid until: January 19th, 2026.`,
  // },
  {
    id: "7",
    image: "https://storage.wsus.me/uploads/sertifikat/pemula-web.JPG",
    href: "https://www.dicoding.com/certificates/EYX4Y7LRWZDL",
    title: "Dicoding - Belajar Membuat Front-End Web untuk Pemula",
    date: "Sep 11st, 2023",
    desc: `Valid until: Sep 11st, 2026.`,
  },
  {
    id: "3",
    image: "https://storage.wsus.me/uploads/sertifikat/dasar-web.JPG",
    href: "https://www.dicoding.com/certificates/1RXY0Y94QZVM",
    title: "Dicoding - Belajar Dasar Pemrograman Web",
    date: "Sep 12st, 2023",
    desc: `Valid until: Sep 12st, 2026.`,
  },
  // {
  //   id: "2",
  //   image: "https://storage.wsus.me/uploads/sertifikat/dasar-react.JPG",
  //   href: "https://www.dicoding.com/certificates/0LZ01G0OQP65",
  //   title: "Dicoding - Belajar Membuat Aplikasi Web dengan React",
  //   date: "Mei 31st, 2021",
  //   desc: `Valid until: Mei 31th, 2024.`,
  // },
  {
    id: "2",
    image: "https://storage.wsus.me/uploads/sertifikat/dasar-be.PNG",
    href: "https://www.dicoding.com/certificates/81P23D2LNXOY",
    title: "Dicoding - Belajar Membuat Aplikasi Back-End untuk Pemula",
    date: "Agustus 8st, 2023",
    desc: `Valid until: Agustus 8st, 2026.`,
  },
];
